import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp } from '../../App';
import './cookies-consent.styles.scss';
import { Button, Card, Modal, Typography } from '@mui/material';

const CookiesConsentBanner = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDecline = () => {
    setOpen(false);
    localStorage.setItem('cookiesAccepted', 'false');
  };

  const handleAccept = () => {
    setOpen(false);
    localStorage.setItem('cookiesAccepted', 'true');
    const analytics = getAnalytics(FirebaseApp);
  };

  if (localStorage.getItem('cookiesAccepted') == 'true') {
    const analytics = getAnalytics(FirebaseApp);
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose} className="center-modal priority">
      <Card className="modal-card">
        <Typography gutterBottom variant="h5" component="div">
          {t('privacyPolicy.bannerHeader')}
        </Typography>
        <div className="inline">
          <Typography gutterBottom variant="body1" component="div">
            {t('privacyPolicy.bannerText')}
          </Typography>
        </div>
        <div className="button-row">
          <Link to="/privacy-policy" onClick={handleClose} className="link button">
            {t('privacyPolicy.privacyPolicy')}
          </Link>
          <Button onClick={handleDecline} variant="text" className="button">
            {t('privacyPolicy.decline')}
          </Button>
          <Button color="primary" variant="contained" className="button" onClick={handleAccept}>
            {t('privacyPolicy.accept')}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default CookiesConsentBanner;
