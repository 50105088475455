import axios from 'axios';
import { DatasetDetails, WordWithTranslation } from './words/words.types';
import { DatasetMetadata, DatasetResponse, RecapMetadata, WordsToLearnResponse } from './responseTypes';
//const serverAddress = 'https://localhost:44386';


const serverAddress = 'https://api.learnwithmovie.com';

export async function createUserIfNotExist() {
  const response = await axios.post(
    serverAddress + '/Users/CreateIfNotExist',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data as number;
}

export async function getWordsToLearnFromDataset(datasetId: string | undefined, userLanguage: string) {
  const response = await axios.get(serverAddress + '/Words/GetWordsToLearn', {
    params: {
      datasetId,
      userLanguage,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  // const response = {
  //   language: 'en',
  //   suggestAssessment: false,
  //   words: [
  //     { translation: { translation: 'translation1' }, word: 'origin1' },
  //     { translation: { translation: 'translation2' }, word: 'origin2' },
  //   ],
  // } as unknown as WordsToLearnResponse;
  return response.data as WordsToLearnResponse;
}

export async function createDataset(text: string, language: string, name: string, isPublic: boolean, kind: string) {
  const response = await axios.post(
    serverAddress + '/Datasets/Create',
    {
      text,
      language,
      name,
      isPublic,
      kind,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data as number;
}

export async function wordLearned(translationId: number, reversed: boolean) {
  const response = await axios.post(
    serverAddress + '/Words/WordLearned',
    {
      translationId,
      reversed,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
}

export async function datasetLearned(datasetId: string | undefined) {
  const response = await axios.post(
    serverAddress + '/Datasets/Learned',
    {
      datasetId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
}

export async function getSampleWordsByPopularity(popularityPercentage: number, language: string | undefined) {
  const response = await axios.get(serverAddress + '/Words/GetByPopularity', {
    params: {
      percentage: popularityPercentage,
      language,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data as string[];
}

export async function saveUserAdvancementLevel(level: number) {
  const response = await axios.post(
    serverAddress + '/Words/UserLevel',
    {
      level,
      language: 'en',
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data + '';
}

export async function getDatasets(
  parentId?: string,
  searchPhrase?: string,
  kind?: string,
  language?: string,
  page?: string
) {
  const response = await axios.post(
    serverAddress + '/Datasets/Search',
    {
      parentId,
      searchPhrase,
      kind,
      language,
      page,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data as DatasetResponse;
}

export async function deleteDataset(id: number) {
  const response = await axios.delete(`${serverAddress}/Datasets/Delete/?id=${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function addToRecap(datasetId: string, translationId: number) {
  const response = await axios.post(
    serverAddress + '/Recap/AddWord',
    {
      translationId,
      datasetId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
}

export async function getRecaps() {
  const response = await axios.get(serverAddress + '/Recap/GetRecaps', {
    params: {},
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data as RecapMetadata[];
}

export async function GetWordsToRecapFromDataset(datasetId?: string) {
  const response = await axios.get(serverAddress + '/Recap/GetWordsToRecapFromDataset', {
    params: {
      datasetId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data as WordWithTranslation[];
}

export async function getDatasetDetails(id: string) {
  const response = await axios.get(serverAddress + '/Datasets/GetDetails', {
    params: {
      id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data as DatasetDetails;
}

export async function sendVote(datasetId: number, positive: boolean) {
  const url = `${serverAddress}/Votes/Vote?datasetId=${datasetId}&positive=${positive}`;

  const response = await axios.post(url, null, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data as boolean;
}

export async function recapWordLearned(translationId: number, isLearned: boolean): Promise<boolean> {
  const url = `${serverAddress}/Recap/RecapWordLearned`;

  const response = await axios.post(
    url,
    { translationId, isLearned },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data as boolean;
}

export async function recapWordNotKnown(translationId: number): Promise<boolean> {
  const url = `${serverAddress}/Recap/RecapWordNotKnown?translationId=${translationId}`;

  const response = await axios.post(url, null, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data as boolean;
}
