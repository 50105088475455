import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.scss';
import { WordWithTranslation } from './api/words/words.types';
import { initializeAxios } from './components/login/login.component';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createUserIfNotExist } from './api/requests';
import CookiesConsentBanner from './static-pages/cookies-consent/cookies-consent-banner';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@material-ui/core/styles';
import DuolingoComparison from './static-pages/blog/duolingo comparison/duolingo-comparison.component';
import ComplimentaryToolArticle from './static-pages/blog/complimentary-tool/complimentary-tool.component';
import LearnWithMovies from './static-pages/blog/learn-with-movies/learn-with-movies.component';

const firebaseConfig = {
  apiKey: 'AIzaSyBRYHFpPt0KFgP0QxQEpN44TIPYe4MZVQI',
  authDomain: 'lesscommon-436f6.firebaseapp.com',
  projectId: 'lesscommon-436f6',
  storageBucket: 'lesscommon-436f6.appspot.com',
  messagingSenderId: '799857922825',
  appId: '1:799857922825:web:475b179a738ed991b7f9d8',
  measurementId: 'G-EV2X3E3FKV',
};

const ProcessText = lazy(() => import('./components/process-text/process-text.component'));
const Login = lazy(() => import('./components/login/login.component'));
const ResponsiveAppBar = lazy(() => import('./components/navigation/navbar.component'));
const VocabularyCheck = lazy(() => import('./components/learn/vocabulary-check/vocabulary-check.component'));
const ExploreDatasets = lazy(() => import('./components/explore-datasets/explore-datasets.component'));
const VocabularyCheckIntroModal = lazy(
  () =>
    import('./components/learn/vocabulary-check/vocabulary-check-intro-modal/vocabulary-check-intro-modal.component')
);
const LandingPage = lazy(() => import('./static-pages/landing-page/landing-page'));
const SuggestRecap = lazy(() => import('./components/learn/recap/suggest-recap-snackbar/suggest-recap.component'));
const RecapList = lazy(() => import('./components/learn/recap/recap-list/recap-list.component'));
const RecapLearn = lazy(() => import('./components/learn/recap/recap-learn.component'));
const TermsOfService = lazy(() => import('./static-pages/terms-of-service/terms-of-service'));
const Credits = lazy(() => import('./static-pages/credits/credits'));
const Learn = lazy(() => import('./components/learn/learn.component'));
const DatasetPage = lazy(() => import('./components/explore-datasets/dataset-page/dataset-page.component'));
const PrivacyPolicyPage = lazy(() => import('./static-pages/privacy-policy/privacy-policy'));

export const FirebaseApp = firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export interface WordsState {
  words: WordWithTranslation[];
  setWords: React.Dispatch<React.SetStateAction<WordWithTranslation[]>>;
  user: string;
  setUser: React.Dispatch<React.SetStateAction<string>>;
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  vocabularyCheckRedirectUrl: string;
  setVocabularyCheckRedirectUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const MyContext = React.createContext<WordsState>({
  words: [{ word: 'test', translation: { translation: 'test2', id: 1 } }],
  setWords: () => {},
  user: '',
  setUser: () => {},
  searchPhrase: '',
  setSearchPhrase: () => {},
  vocabularyCheckRedirectUrl: '/',
  setVocabularyCheckRedirectUrl: () => {},
});

//each page needs to have a corresponding :language path defined otherwise it won't work on page reload
export const AppRoutes = [
  { path: '/', component: ExploreDatasets },
  { path: '/privacy-policy', component: PrivacyPolicyPage },
  { path: '/terms-of-service', component: TermsOfService },
  { path: '/about', component: LandingPage },
  { path: '/login', component: Login },
  { path: '/learn/:name/:id', component: Learn },
  { path: '/dataset/:name/:id', component: DatasetPage },
  { path: '/explore-datasets/', component: ExploreDatasets },
  { path: '/explore-datasets/page/:selectedPage', component: ExploreDatasets },
  { path: '/explore-datasets/:segment1/:id', component: ExploreDatasets },
  { path: '/explore-datasets/:segment1/:segment2/:id', component: ExploreDatasets },
  { path: '/create', component: ProcessText },
  { path: '/vocabularyCheck/:assessmentLanguage', component: VocabularyCheck },
  { path: '/vocabularyCheck', component: VocabularyCheckIntroModal },
  { path: '/recap', component: RecapList },
  { path: '/recap/:id', component: RecapLearn },
  { path: '/credits', component: Credits },
  { path: '/blog/compare', component: DuolingoComparison },
  { path: '/blog/learn_with_movies', component: LearnWithMovies },
  { path: '/blog/how_learnWithMovie_complements_duolingo', component: ComplimentaryToolArticle },

  { path: '/:language', component: ExploreDatasets },
  { path: '/:language/privacy-policy', component: PrivacyPolicyPage },
  { path: '/:language/terms-of-service', component: TermsOfService },
  { path: '/:language/about', component: LandingPage },
  { path: '/:language/login', component: Login },
  { path: '/:language/learn/:name/:id', component: Learn },
  { path: '/:language/dataset/:name/:id', component: DatasetPage },
  { path: '/:language/explore-datasets/', component: ExploreDatasets },
  { path: '/:language/explore-datasets/page/:selectedPage', component: ExploreDatasets },
  { path: '/:language/explore-datasets/:segment1/:id', component: ExploreDatasets },
  { path: '/:language/explore-datasets/:segment1/:segment2/:id', component: ExploreDatasets },
  { path: '/:language/create', component: ProcessText },
  { path: '/:language/vocabularyCheck/:assessmentLanguage', component: VocabularyCheck },
  { path: '/:language/vocabularyCheck', component: VocabularyCheckIntroModal },
  { path: '/:language/recap', component: RecapList },
  { path: '/:language/recap/:id', component: RecapLearn },
  { path: '/:language/credits', component: Credits },
];

function App() {
  useEffect(() => {
    initializeAxios();
  }, []);

  const [words, setWords] = useState<WordWithTranslation[]>([]);
  const [user, setUser] = useState('');
  const [searchPhrase, setSearchPhrase] = useState('');
  const [vocabularyCheckRedirectUrl, setVocabularyCheckRedirectUrl] = useState('');

  const auth = getAuth();
  onAuthStateChanged(auth, (firebaseUser) => {
    if (firebaseUser) {
      const uid = firebaseUser.uid;
      if (!user) {
        createUserIfNotExist();
      }
      setUser(uid);
    } else {
      setUser('');
    }
  });

  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 900,
        fontSize: '50px',
      },
      h2: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: '35px',
      },
      h3: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: '30px',
      },
      h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: '28px',
      },
      h5: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: '25px',
      },
    },

    overrides: {
      MuiButton: {
        root: {
          backgroundColor: '#010101',
          borderRadius: '30',
        },
      },
    },
    palette: {
      primary: {
        main: '#F49D1A',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#DC3535',
      },
      warning: {
        main: '#FFE15D',
      },
      success: {
        main: '#939B62',
      },
      info: {
        main: '#FFE5CA',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MyContext.Provider
        value={{
          words,
          setWords,
          user,
          setUser,
          searchPhrase,
          setSearchPhrase,
          vocabularyCheckRedirectUrl,
          setVocabularyCheckRedirectUrl,
        }}
      >
        <div className="App">
          <Router>
            <Suspense>
              <ResponsiveAppBar />
              <div className="content-container">
                <Routes>
                  {AppRoutes.map(({ path, component: Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                  ))}
                </Routes>
              </div>
              <CookiesConsentBanner />
              <SuggestRecap />
            </Suspense>
          </Router>
        </div>
      </MyContext.Provider>
    </ThemeProvider>
  );
}

export default App;
