import { Card } from '@mui/material';
import './duolingo-comparison.styles.scss';

const DuolingoComparison = () => {
  return (
    <div className="privacy-policy-content-container">
      <div className="content">
        <h1>LearnWithMovie vs. Leading Language Learning Apps:</h1>
        <h2>Overview</h2>
        <p>
          Discover how LearnWithMovie's unique approach to language learning through TV series and movies stacks up
          against some of the most popular language learning apps in the market. This comparison highlights
          LearnWithMovie's distinctive strengths while providing brief insights into the notable features of its
          competitors, including
          <b> Duolingo</b>, <b>Rosetta Stone</b>, and <b>Babbel</b>. Whether you prefer contextual, real-world language
          learning or structured lessons, this guide will help you make an informed choice.
        </p>
        <br />
        <table>
          <thead>
            <tr>
              <th>
                <span>Feature Comparison</span>
              </th>
              <th>
                <span>LearnWithMovie</span>
              </th>
              <th>
                <span>Duolingo</span>
              </th>
              <th>
                <span>Rosetta Stone</span>
              </th>
              <th>
                <span>Babbel</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>Content Source</span>
              </td>
              <td>Learn from TV Series and Movies</td>
              <td>Structured Language Courses</td>
              <td>Structured Language Courses</td>
              <td>Structured Language Courses</td>
            </tr>
            <tr>
              <td>
                <span>Learning Approach</span>
              </td>
              <td>Contextual Learning from Entertainment</td>
              <td>Gamified Lessons and Quizzes</td>
              <td>Immersive Environment with Visual and Audio Learning</td>
              <td>Interactive Lessons with Real-Life Scenarios</td>
            </tr>
            <tr>
              <td>
                <span>User-Generated Content</span>
              </td>
              <td>Yes</td>
              <td>Limited</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                <span>Personalization</span>
              </td>
              <td>Algorithm-Based Customization</td>
              <td>Standardized Placement Test</td>
              <td>Adaptive Learning and Tracking</td>
              <td>Personalized Learning Paths</td>
            </tr>
          </tbody>
        </table>
        <h2>LearnWithMovie Summary:</h2>
        <ul>
          <li>Unique and engaging learning approach through TV series and movies.</li>
          <li>User-generated content allows for diverse learning materials.</li>
          <li>Algorithm-based learning tailors the experience to individual language levels.</li>
          <li>Offers an alternative to traditional language learning methods.</li>
          <li>Interactive and enjoyable learning through favorite films and TV shows</li>
        </ul>

        <h2>Competitor Highlights:</h2>
        <ul>
          <li>
            <b>Duolingo</b>: Highly popular and accessible for all levels of language learners.
          </li>
          <li>
            <b>Rosetta</b> Stone: Well-established and renowned for its immersive approach.
          </li>
          <li>
            <b>Babbel</b>: Known for personalized learning paths and practical real-life scenarios.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DuolingoComparison;
