import { useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { auth } from 'firebaseui';
import './login.styles.scss';
import { useNavigate } from 'react-router-dom';

export function initializeAxios() {
  axios.interceptors.request.use(async (config) => {
    const accessToken = await firebase.auth().currentUser?.getIdToken(true);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
}

const Login = () => {
  const navigate = useNavigate();

  const script = document.createElement('script');
  script.src = 'https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js';
  script.async = true;
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css';
  document.head.appendChild(link);
  document.body.appendChild(script);

  useEffect(() => {
    var ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth());

    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
      ],
      tosUrl: 'https://learnwithmovie.com/terms-of-service',
      signInFlow: 'popup',
      callbacks: {
        signInSuccessWithAuthResult: function (authResult) {
          localStorage.setItem('cookiesAccepted', 'true');
          localStorage.setItem('user', JSON.stringify(authResult.user));
          navigate('/explore-datasets');
          return false;
        },
      },
      privacyPolicyUrl: 'https://learnwithmovie.com/privacy-policy',
    });
  }, []);
  return <div id="firebaseui-auth-container" className="center"></div>;
};

export default Login;
